<template>
  <section id="product">
    <div class="banner" :style="{ height: height, backgroundSize: bgSize }"></div>
    <div class="nav-area">
      <b-container class="container">
        <b-row>
          <b-col class="breadcrumb_box">
            <span class="icon-icon_home iconfont"></span>
            <b-breadcrumb separator-class="el-icon-arrow-right">
              <b-breadcrumb-item to="/">
                <span>首页</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="(item, index) in pathCrumb" :key="index" :to="item.to">{{ item.name }}</b-breadcrumb-item>
            </b-breadcrumb>
          </b-col>

          <b-col class="tabs_box">
            <ul>
              <li
                v-for="(item, index) in tabsList"
                :key="index"
                :class="{
                  active: index == tabIndex,
                }"
                @click="goAnchor(index)"
              >
                {{ item }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="tabPan">
      <b-container id="nav_1">
        <div class="content">
          <div class="title"><span class="bd"></span>四大类产品</div>
          <div class="tit">用户范围：法官、检察官、行政执法人员、政法工作人员、企业、律师、法学教师、学生、普通群众</div>
          <div class="cont">
            <div class="product-card" v-for="(item, index) in productList" :key="index">
              <div class="top">
                <img :src="item.icon" width="60" alt="" />
                <span>{{ item.title }}</span>
                <div class="bg">0{{ index + 1 }}</div>
              </div>
              <div class="bottom">
                <ul>
                  <li v-for="(el, i) in item.arr" :key="i">
                    <router-link :to="el.href">{{ el.name }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </b-container>

      <b-container id="nav_2">
        <div class="content">
          <div class="title" style="padding-top: 10px"><span class="bd"></span>两大类数据服务</div>
          <div class="tit">服务对象：法院、检察院、政法委、国有企业、银行、律师事务所等</div>
          <div class="cont">
            <el-row :gutter="24" class="serve-card">
              <el-col :xs="24" :sm="24" :md="12" :lg="12" v-for="(item, index) in serveList" :key="index">
                <img :src="item.icon" width="100%" alt="" />
                <router-link
                  :to="item.href"
                  :style="{
                    'margin-left': '-' + item.title.length * 8 + 'px',
                  }"
                  >{{ item.title }}</router-link
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </b-container>

      <b-container id="nav_3">
        <div class="content">
          <div class="title" style="padding-top: 10px"><span class="bd"></span>一个开放平台</div>
          <div class="tit">服务对象：法院、检察院、政法委、国有企业、银行、律师事务所等</div>
          <div class="cont">
            <el-row :gutter="24" class="serve-card">
              <el-col :xs="24" :sm="24" :md="24" :lg="24">
                <img src="../assets/product/img3.png" width="100%" alt="" />
                <a class="open-link" @click="goCloudBridge"> 把手科技接口平台 </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </b-container>
    </div>
  </section>
</template>

<script>
import { navItemList } from "@/baseData/index";
export default {
  data() {
    return {
      navItemList,
      pathCrumb: [],
      activeName: "first",
      tabsList: ["产品体系", "服务体系"],
      tabIndex: 0,
      productList: [
        {
          title: "法律数据库",
          icon: require("../assets/product/icon1.png"),
          arr: [
            {
              name: "全国法律法规数据库",
              href: "/productDetail?name=全国法律法规数据库",
            },
            {
              name: "全国司法案例数据库",
              href: "/productDetail?name=全国司法案例数据库",
            },
            {
              name: "全国行政处罚数据库",
              href: "/productDetail?name=全国行政处罚数据库",
            },
          ],
        },
        {
          title: "司法辅助工具",
          icon: require("../assets/product/icon2.png"),
          arr: [
            {
              name: "法官留言微信小程序",
              href: "/productDetail?name=法官留言微信小程序",
            },
            {
              name: "刑事类案检索系统",
              href: "/productDetail?name=刑事类案检索系统",
            },
            {
              name: "刑事精准化量刑辅助系统",
              href: "/productDetail?name=刑事精准化量刑辅助系统",
            },
            {
              name: "裁判文书纠错",
              href: "/productDetail?name=裁判文书纠错",
            },
            {
              name: "把手法律助手",
              href: "/productDetail?name=把手法律助手",
            },
          ],
        },
        // {
        //   title: "行政辅助应用",
        //   icon: require("../assets/product/icon3.png"),
        //   arr: [
        //     // {
        //     //     name: '法官留言小程序',
        //     //     href: '/productDetail?name=法官留言小程序'
        //     // },
        //     {
        //       name: "行政执法监督管理系统",
        //       href: "/productDetail?name=行政执法监督管理系统",
        //     },
        //   ],
        // },
        {
          title: "企业风控系统",
          icon: require("../assets/product/icon4.png"),
          arr: [
            {
              name: "企业法律风险自检系统",
              href: "/productDetail?name=企业法律风险自检系统",
            },
            {
              name: "企业法律风险指引系统",
              href: "/productDetail?name=企业法律风险指引系统",
            },
          ],
        },
        {
          title: "公共法律服务",
          icon: require("../assets/product/icon5.png"),
          arr: [
            {
              name: "常见法律问题智能咨询系统",
              href: "/productDetail?name=常见法律问题智能咨询系统",
            },
            {
              name: "常见法律文书自动生成系统",
              href: "/productDetail?name=常见法律文书自动生成系统",
            },
          ],
        },
      ],
      serveList: [
        {
          title: "类案检索服务",
          icon: require("../assets/product/img1.png"),
          href: "/productDetail?name=类案检索服务",
        },
        {
          title: "法律数据分析服务",
          icon: require("../assets/product/img2.png"),
          href: "/productDetail?name=法律数据分析服务",
        },
      ],
      height: 0,
      bgSize: "190px,cover",
    };
  },

  methods: {
    // 动态设置banner图高度
    calcH() {
      let calcHeight = Math.floor((document.body.clientWidth * 875) / 1920) - 10;
      this.height = `${calcHeight}px`; //动态计算轮播图的高度;
      if (calcHeight < 200) {
        this.bgSize = "120px,cover";
      }
    },
    goAnchor(index) {
      this.tabIndex = index;
      let selector = `#nav_${index + 1}`;
      let anchor = document.querySelector(selector); // 参数为要跳转到的元素id
      window.scrollTo({
        top: anchor.offsetTop - (index == 0 ? 98 : 57),
        behavior: "smooth",
      });
    },
    goCloudBridge() {
      window.open(process.env.VUE_APP_CLOUD_BRIDGE, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
#product {
  background: #fff;
}
@media screen and (min-width: 1368px) {
  .banner {
    height: 30rem;
  }
  .container {
    max-width: 1368px !important;
    padding-left: 0;
    padding-right: 0;
    // overflow: hidden;
  }
}
section {
  background: #f0f2f5;
  .banner {
    position: relative;
    max-height: 39rem;
    background-image: url("../assets/product/banner-icon.png"), url("../assets/product/banner.png");
    background-repeat: no-repeat;
    background-position: 10% 60%, center;
    background-size: 190px, cover;
  }
  .nav-area {
    width: 100%;
    height: 58px;
    color: #666666;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    position: sticky;
    top: 82px;
    background: #fff;
    z-index: 11;
  }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .row {
      margin: 0;
    }
    .breadcrumb_box {
      display: flex;
      align-items: center;
      height: 58px;
      .breadcrumb-item {
        align-items: center;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "";
        width: 0;
        height: 0;
        border-width: 4px;
        border-style: solid;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: #6c757d;
        border-right: 0;
      }
    }
    ol {
      font-size: 1.5rem;
      background-color: #fff;
      margin-top: 1rem;
      a {
        color: #666666;
        span + span {
          margin-left: 6px;
        }
      }
    }

    .tabs_box {
      height: 58px;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: -2px 0 0;
        padding: 0;
        height: 100%;
        li {
          & + li {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
          }
          height: 100%;
          display: flex;
          align-items: center;
          list-style: none;
          font-size: 1.5rem;
          border-bottom: 2px solid transparent;
          margin-bottom: -1px;
          &.active {
            color: #015aab;
            border-bottom: 2px solid #015aab;
          }
        }
      }
    }
  }

  .tabPan {
    margin-top: 80px;
    .content {
      width: 100%;
      margin-bottom: 80px;
      .title {
        font-size: 3rem;
        color: #333333;
        line-height: 32px;
        display: flex;
        span {
          width: 4px;
          height: 28px;
          background: #015aab;
          display: inline-block;
          margin-right: 10px;
          margin-top: 2px;
        }
      }
      .tit {
        font-size: 1.5rem;
        color: #666666;
        line-height: 32px;
        margin: 18px 0 20px;
      }
      .cont {
        display: flex;
        justify-content: space-between;
        .product-card {
          width: 256px;
          height: 440px;
          background: #f4f7fa;
          margin-right: 20px;
          &:last-child(2n) {
            margin-right: 0;
          }
          .top {
            background: #015aab;
            height: 210px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
              margin-bottom: 30px;
            }
            span {
              color: #fff;
              font-size: 2.4rem;
              font-weight: 300;
              line-height: 24px;
            }
            .bg {
              font-size: 7.2rem;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
              opacity: 0.05;
              position: absolute;
              bottom: 50px;
            }
          }
          .bottom {
            padding-top: 28px;
            ul {
              padding: 0;
              li {
                list-style: none;
                font-size: 1.5rem;
                line-height: 38px;
                text-align: center;
                a {
                  color: #555555;
                  &:hover {
                    text-decoration: none;
                    color: #015aab;
                    border-bottom: 1px solid #015aab;
                  }
                }
              }
            }
          }
        }
        .serve-card {
          width: 100%;
          margin: 0;
          .el-col {
            position: relative;
            margin-bottom: 10px;
          }
          a {
            font-size: 2.4rem;
            font-weight: 300;
            color: #ffffff;
            line-height: 32px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -16px;
            &:hover {
              text-decoration: navajowhite;
              border-bottom: 1px solid #fff;
            }
          }

          .open-link {
            color: #015aab;
            cursor: pointer;

            &:hover {
              text-decoration: navajowhite;
              border-bottom: 1px solid #015aab;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 426px) {
  section {
    .breadcrumb_box {
      display: none !important;
    }
    .tabs_box {
      padding: 0;
      ul {
        justify-content: flex-start !important;
      }
    }
    .tabPan {
      margin-top: 3rem;
      .content {
        margin-bottom: 3rem;
      }
    }
  }
}
@media screen and (min-width: 320px) and(max-width: 767px) {
  section {
    .tabPan .content .cont {
      display: flex;
      flex-wrap: wrap;
      .product-card {
        width: 48%;
        margin: 0 1% 10px;
        height: 350px;
        .top {
          height: 170px;
        }
        .bottom ul li {
          font-size: 1.4rem;
          line-height: 30px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and(max-width: 1023px) {
  section {
    .cont {
      display: block !important;
      .product-card {
        width: 48% !important;
        height: 210px;
        margin-bottom: 20px;
        margin-right: 2% !important;
        float: left;
        &:nth-child(2n) {
          margin-right: 0 !important;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and(max-width: 1279px) {
  section {
    .cont {
      display: block !important;
      .product-card {
        width: 31% !important;
        height: 210px;
        margin-bottom: 20px;
        margin-right: 3% !important;
        float: left;
        &:nth-child(3n) {
          margin-right: 0 !important;
        }
      }
    }
  }
}
</style>
